import * as React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout  from "../components/layout/layout"
import Post from '../components/post/post';
import Seo from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes;
  const pogiffDescription = ['“Words are dumb, Pogiff” We’re not sure what our Artificial Intelligence meant.']

  return (
    <Layout>
      <Seo title="Home" />
     <h1>What is a Pogiff?</h1>
     <h2 style={{marginTop:75,marginBottom:75}}>Who knows. Dadaists created the word, we’ve adopted it to describe the absurdity of this or anytime.*</h2>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage{
            childImageSharp{
              fluid(maxWidth:800){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
